/* eslint-disable @typescript-eslint/no-explicit-any */
import { StockLedgerEntry, Page, Warehouse } from "@/types";
import { api } from "@/util/axios";
import { Module } from "vuex";

type StockInventoryState = {
  stockLedgerEntryPage: Page<StockLedgerEntry>;
  warehousePage: Page<Warehouse>;
  stockEntries: StockLedgerEntry[];
  warehouses: Warehouse[];
};

const stockInventory: Module<StockInventoryState, unknown> = {
  namespaced: true,
  state: () => ({
    stockLedgerEntryPage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
    warehousePage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
    stockEntries: [],
    warehouses: [],
  }),
  mutations: {
    // Stock Entry Mutations
    ADD_STOCK_ENTRY: (state, stockEntry) => {
      const index = state.stockEntries.findIndex(
        (item) => item._id === stockEntry._id
      );
      if (index !== -1) {
        state.stockEntries.splice(index, 1, stockEntry);
      } else {
        state.stockEntries.push(stockEntry);
      }
    },
    SET_STOCK_ENTRY_PAGE: (state, stockEntryPage) => {
      state.stockLedgerEntryPage = stockEntryPage;
      state.stockEntries = stockEntryPage.docs;
    },
    REMOVE_STOCK_ENTRY: (state, stockEntryId) => {
      state.stockEntries = state.stockEntries.filter(
        (item) => item._id !== stockEntryId
      );
    },

    // Warehouse Mutations
    ADD_WAREHOUSE: (state, warehouse) => {
      const index = state.warehouses.findIndex(
        (item) => item._id === warehouse._id
      );
      if (index !== -1) {
        state.warehouses.splice(index, 1, warehouse);
      } else {
        state.warehouses.push(warehouse);
      }
    },
    SET_WAREHOUSE_PAGE: (state, warehousePage) => {
      state.warehousePage = warehousePage;
      state.warehouses = warehousePage.docs;
    },
    REMOVE_WAREHOUSE: (state, warehouseId) => {
      state.warehouses = state.warehouses.filter(
        (item) => item._id !== warehouseId
      );
    },
  },
  getters: {
    // Stock Entry Getters
    stockLedgerEntryPage: (state) => state.stockLedgerEntryPage,
    getStockEntry: (state) => (stockEntryId: string) =>
      state.stockEntries.find((entry) => entry._id === stockEntryId),

    // Warehouse Getters
    warehousePage: (state) => state.warehousePage,
    getWarehouse: (state) => (warehouseId: string) =>
      state.warehouses.find((warehouse) => warehouse._id === warehouseId),
  },
  actions: {
    // Stock Entry Actions
    createStockEntry({ commit, dispatch }, payload) {
      return api
        .post("/v1/stock/", payload)
        .then((response) => {
          commit("ADD_STOCK_ENTRY", response.data.stockEntry);
          dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Stock Entry Created",
            },
            { root: true }
          );
          return response.data.stockEntry;
        })
        .catch((error) => {
          dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text:
                error.response?.data?.error?.message ||
                error.message ||
                "An error occurred",
            },
            { root: true }
          );
          throw error;
        });
    },
    fetchStockEntries({ commit, dispatch }, params = "") {
      return api
        .get(`/v1/stock`, { params })
        .then((response) => {
          commit("SET_STOCK_ENTRY_PAGE", response.data.stockEntries);
          return response.data.stockEntries;
        })
        .catch((error) => {
          dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text:
                error.response?.data?.error?.message ||
                error.message ||
                "An error occurred",
            },
            { root: true }
          );
          throw error;
        });
    },
    deleteStockEntry({ commit, dispatch }, stockEntryId) {
      return api
        .delete(`/v1/stock/${stockEntryId}`)
        .then(() => {
          commit("REMOVE_STOCK_ENTRY", stockEntryId);
          dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Stock Entry Deleted",
            },
            { root: true }
          );
        })
        .catch((error) => {
          dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text:
                error.response?.data?.error?.message ||
                error.message ||
                "An error occurred",
            },
            { root: true }
          );
          throw error;
        });
    },
    getStockBalance({ dispatch }, params = "") {
      return api
        .get(`/v1/stock/balance${params}`)
        .then((response) => {
          return response.data.balance;
        })
        .catch((error) => {
          dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text:
                error.response?.data?.error?.message ||
                error.message ||
                "An error occurred",
            },
            { root: true }
          );
          throw error;
        });
    },

    // Warehouse Actions
    createWarehouse({ commit, dispatch }, payload) {
      return api
        .post("/v1/warehouse/", payload)
        .then((response) => {
          commit("ADD_WAREHOUSE", response.data.warehouse);
          dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Warehouse Created",
            },
            { root: true }
          );
          return response.data.warehouse;
        })
        .catch((error) => {
          dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text:
                error.response?.data?.error?.message ||
                error.message ||
                "An error occurred",
            },
            { root: true }
          );
          throw error;
        });
    },
    fetchWarehouses({ commit, dispatch }, params = "") {
      return api
        .get(`/v1/warehouse`, { params })
        .then((response) => {
          commit("SET_WAREHOUSE_PAGE", response.data.warehousePage);
          return response.data.warehousePage;
        })
        .catch((error) => {
          dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text:
                error.response?.data?.error?.message ||
                error.message ||
                "An error occurred",
            },
            { root: true }
          );
          throw error;
        });
    },
    updateWarehouse({ commit, dispatch }, payload) {
      const { id, ...data } = payload;
      return api
        .put(`/v1/warehouse/${id}`, data)
        .then((response) => {
          commit("ADD_WAREHOUSE", response.data.warehouse);
          dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Warehouse Updated",
            },
            { root: true }
          );
          return response.data.warehouse;
        })
        .catch((error) => {
          dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text:
                error.response?.data?.error?.message ||
                error.message ||
                "An error occurred",
            },
            { root: true }
          );
          throw error;
        });
    },
    deleteWarehouse({ commit, dispatch }, payload) {
      const { id, businessId } = payload;
      return api
        .delete(`/v1/warehouse/${id}`, {
          params: {
            businessId,
          },
        })
        .then((response) => {
          commit("REMOVE_WAREHOUSE", id);
          dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Warehouse Deleted",
            },
            { root: true }
          );
          return response.data.warehouse;
        })
        .catch((error) => {
          dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text:
                error.response?.data?.error?.message ||
                error.message ||
                "An error occurred",
            },
            { root: true }
          );
          throw error;
        });
    },
  },
};

export default stockInventory;
