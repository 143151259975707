





































































































































































































































































































































































































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import productInventoryModule from "@/store/modules/productInventory";
import productCategoryModule from "@/store/modules/productCategory";
import productOptionModule from "@/store/modules/productOptions";
import { Business, InventoryProduct, InventoryVariant, Role } from "@/types";

import Uploader from "@/components/fragments/uploader.vue";
import VariantEdit from "@/components/inventory/ProductVariantEdit.vue";

const VUE_APP_API_URL = process.env.VUE_APP_API_URL;

const { mapActions: productActions, mapGetters: productGetters } =
  createNamespacedHelpers("INVENTORY_EDIT");

const { mapActions: categoryActions, mapGetters: categoryGetters } =
  createNamespacedHelpers("INV_CATEGORY");

const { mapGetters: optionGetters, mapActions: optionActions } =
  createNamespacedHelpers("INV_OPTIONS");

const rules = {
  title: [(v: any) => !!v || "Product Title is required"],
  short_desc: [
    (v: any) => !!v || "Product short description is required",
    (v: any) =>
      (v || "").length <= 200 ||
      "Product Short Description shouldn`t be lengthy",
  ],
};

export default Vue.extend<any, any, any, any>({
  name: "Product",
  props: {
    productId: {
      type: String,
      required: true,
    },
  },
  components: { Uploader, VariantEdit },
  data: () => ({
    apiUrl: VUE_APP_API_URL,
    edittedVariant: undefined as undefined | InventoryVariant,
    product_status: ["draft", "pending", "publish"],
    valid: false,
    editVariantDialog: false,
    product_images: [] as Blob[],
    rules: rules,
    variant_headers: [
      {
        text: "",
        sortable: false,
        value: "title",
      },
      {
        text: "SKU",
        align: "start",
        sortable: false,
        value: "sku",
      },
      {
        text: "Specifications",
        align: "start",
        sortable: false,
        value: "specifics",
      },
      {
        text: "Pricing",
        align: "start",
        sortable: false,
        value: "price",
      },
      {
        text: "Stock",
        align: "start",
        sortable: false,
        value: "stock",
      },
      // {
      //   text: "Stock",
      //   align: "start",
      //   sortable: false,
      //   value: "stock.stock_position",
      // },
      {
        text: "",
        sortable: false,
        value: "actions",
      },
    ],
    categoryRules: [(v) => !!v || "Category Field is required"],
  }),
  computed: {
    role(): Role {
      return this.$store.getters.role;
    },
    vendor(): Business {
      return this.role.business as Business;
    },
    ...productGetters(["getProduct"]),
    ...optionGetters(["productOptions"]),
    product(): InventoryProduct {
      return this.getProduct(this.productId);
    },
    ...categoryGetters(["categoryPage"]),
  },
  watch: {
    role() {
      this.fetchProductOptions();
      this.fetchProductCategories();
    },
  },
  mounted() {
    this.getProductById(this.productId);
    this.fetchProductOptions();
    this.fetchProductCategories();
  },
  methods: {
    ...productActions([
      "fetchProduct",
      "updateProduct",
      "updateImage",
      "updateVariant",
      "updateVariantImage",
      "reviewProduct",
    ]),
    ...optionActions(["fetchOptions"]),
    ...categoryActions(["fetchCategoryList"]),
    optionsValues(option_name_id: string): any[] {
      if (this.productOptions && this.product) {
        const option = Object.values<{ _id: string; option_values: any[] }>(
          this.productOptions
        ).filter((opt) => opt._id === option_name_id)[0];
        return option.option_values;
      }
      return [];
    },
    fetchProductCategories() {
      if (this.role && this.vendor) {
        const params = `?businessId=${this.vendor._id}`;
        this.fetchCategoryList(params);
      }
    },
    fetchProductOptions() {
      if (this.role && this.vendor) {
        const params = `?businessId=${this.vendor._id}`;
        this.fetchOptions(params).then((r) => console.log(r));
      }
    },
    getProductById(productId: string) {
      this.fetchProduct(`?productId=${productId}`);
    },
    editVariant(variant: InventoryVariant) {
      this.edittedVariant = variant;
      this.editVariantDialog = true;
    },
    saveProduct() {
      const {
        title,
        status,
        product_type,
        short_description,
        attributes = [],
        description,
        variants = [],
        category,
      } = this.product;
      if (this.product.product_type === "simple") {
        let data = {
          title,
          status,
          product_type,
          short_description,
          description,
          variants: [
            {
              pricing: variants[0].pricing,
              stock: variants[0].stock,
              shipping: variants[0].shipping || {
                dimensions: { length: 0, width: 0, height: 0 },
              },
            },
          ],
          category: category?._id ? category._id : category,
          attributes,
        };
        this.updateProduct({ id: this.product._id, product: data });
      }

      if (this.product.product_type === "variant") {
        let data = {
          title,
          status,
          product_type,
          short_description,
          description,
          category: category?._id ? category._id : category,
          attributes,
        };
        this.updateProduct({ id: this.product._id, product: data });
      }
      //update product thumbnails
      if (this.product_images.length > 0) {
        let formData = new FormData();
        this.product_images.forEach((image) => formData.append("files", image));
        this.updateImage({ id: this.product._id, data: formData });
      }
    },

    saveVariant(data: { variant: InventoryVariant; files: Blob[] }) {
      const { pricing, stock, _id, shipping } = data.variant;
      if (data.files.length > 0) {
        let formData = new FormData();
        data.files.forEach((image) => formData.append("files", image));
        this.updateVariantImage({ id: _id, data: formData });
      }
      this.updateVariant({ id: _id, stock, pricing, shipping }).then(() => {
        this.getProductById(this.productId);
        this.editVariantDialog = false;
      });
    },
    goback() {
      this.$router.push("/products");
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("INVENTORY_EDIT")) {
      this.$store.registerModule("INVENTORY_EDIT", productInventoryModule);
    }

    if (!this.$store.hasModule("INV_CATEGORY")) {
      this.$store.registerModule("INV_CATEGORY", productCategoryModule);
    }

    if (!this.$store.hasModule("INV_OPTIONS")) {
      this.$store.registerModule("INV_OPTIONS", productOptionModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("INVENTORY_EDIT");
    this.$store.unregisterModule("INV_CATEGORY");
    this.$store.unregisterModule("INV_OPTIONS");
  },
});
