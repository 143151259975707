





































































































































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";
import { StockLedgerEntry, Warehouse, Page, Product } from "@/types";
import stockInventoryModule from "@/store/modules/stock"; // Import the module
import productInventoryModule from "@/store/modules/productInventory";

const { mapActions } = createNamespacedHelpers("stockInventory");
const {
  mapGetters: inventoryProductGetters,
  mapActions: inventoryProductActions,
} = createNamespacedHelpers("INVENTORY_PRODUCT");

export default Vue.extend<any, any, any, any>({
  name: "StockEntryList",
  data() {
    return {
      loading: false,
      stockEntry: undefined as StockLedgerEntry | undefined,
      filters: {
        transactionType: null as string | null,
        search: "",
        warehouse: null as string | null,
      },
      transactionTypes: ["IN", "OUT"],
      warehouseOptions: [] as Array<string>,
      headers: [
        { text: "Item Code", value: "itemCode" },
        { text: "Warehouse", value: "warehouse" },
        { text: "Transaction Type", value: "transactionType" },
        { text: "Quantity", value: "quantity", align: "right" },
        { text: "Reference", value: "referenceType" },
        { text: "Reference No,", value: "reference" },
        { text: "Date", value: "createdAt" },
        // { text: "Actions", value: "action", sortable: false },
      ],
      options: {} as { page: number; itemsPerPage: number },
      deleteDialog: false,
      addDialog: false,
      newStockEntry: {
        itemCode: "",
        warehouseId: "",
        transactionType: "",
        quantity: null as number | null,
        reference: "",
      },
      warehouseList: [] as Warehouse[],
      productList: [] as Product[],
      rules: {
        required: (value: any) => !!value || "Required.",
        numeric: (value: any) =>
          !isNaN(parseFloat(value)) || "Must be a number.",
      },
    };
  },
  computed: {
    ...inventoryProductGetters(["productPage"]),
    stockLedgerEntryPage(): Page<StockLedgerEntry> {
      return this.$store.getters["stockInventory/stockLedgerEntryPage"];
    },
    items() {
      return this.productPage?.docs ?? [];
    },
    filteredData(): StockLedgerEntry[] {
      let data = this.stockLedgerEntryPage?.docs || [];

      // Apply transaction type filter
      if (this.filters.transactionType) {
        data = data.filter(
          (item) => item.transactionType === this.filters.transactionType
        );
      }

      // Apply search filter
      if (this.filters.search) {
        const search = this.filters.search.toLowerCase();
        data = data.filter(
          (item) =>
            item.itemCode.title.toLowerCase().includes(search) ||
            (item.reference && item.reference.toLowerCase().includes(search))
        );
      }

      // Apply warehouse filter
      if (this.filters.warehouse) {
        data = data.filter((item) => {
          if (item.warehouse && typeof item.warehouse === "object") {
            return item.warehouse.name === this.filters.warehouse;
          } else if (typeof item.warehouse === "string") {
            return item.warehouse === this.filters.warehouse;
          }
          return false;
        });
      }

      return data;
    },
  },
  methods: {
    ...mapActions([
      "fetchStockEntries",
      "deleteStockEntry",
      "createStockEntry",
      "fetchWarehouses",
    ]),
    ...inventoryProductActions(["fetchProductList"]),
    loadStockEntries() {
      this.loading = true;
      const params = {
        page: this.options.page || 1,
        limit: this.options.itemsPerPage || 10,
        sort: "-createdAt",
        businessId: this.$store.getters.role.business._id,
      };

      this.fetchStockEntries(params)
        .then(() => {
          this.loading = false;
          this.loadWarehouseOptions();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    loadProducts() {
      if (this.$store.getters.role) {
        const params = `?businessId=${this.$store.getters.role.business._id}`;
        this.fetchProductList(params);
      }
    },
    loadWarehouseOptions() {
      this.warehouseOptions = [
        ...new Set(
          this.stockLedgerEntryPage.docs
            .filter((item) => item.warehouse)
            .map((item) => {
              if (item.warehouse && typeof item.warehouse === "object") {
                return item.warehouse.name;
              } else if (typeof item.warehouse === "string") {
                return item.warehouse;
              }
              return "";
            })
            .filter((name) => name) // Remove empty strings
        ),
      ];
    },
    deleteConfirm(item: StockLedgerEntry) {
      this.stockEntry = item;
      this.deleteDialog = true;
    },
    deleteEntry() {
      if (this.stockEntry) {
        this.deleteStockEntry(this.stockEntry._id)
          .then(() => {
            this.deleteDialog = false;
            this.loadStockEntries();
            this.$store.dispatch(
              "setToast",
              {
                title: "Success",
                type: "success",
                text: "Stock Entry Deleted",
              },
              { root: true }
            );
          })
          .catch(() => {
            this.deleteDialog = false;
          });
      }
    },
    openAddDialog() {
      this.addDialog = true;
      this.loadProducts();
      this.fetchWarehouses({
        page: 1,
        limit: 100,
        businessId: this.$store.getters.role.business._id,
      }).then((response: any) => {
        this.warehouseList = response.docs || [];
      });
    },
    closeAddDialog() {
      this.addDialog = false;
      this.resetNewStockEntry();
    },

    submitStockEntry() {
      const valid = (this.$refs.addStockForm as any).validate();
      if (valid) {
        const payload = {
          itemCode: this.newStockEntry.itemCode,
          warehouseId: this.newStockEntry.warehouseId,
          transactionType: "IN",
          quantity: this.newStockEntry.quantity,
          businessId: this.$store.getters.role.business._id,
          reference: this.$store.getters.role.user.email,
          referenceType: "Reconciliation",
        };
        this.createStockEntry(payload)
          .then(() => {
            this.closeAddDialog();
            this.loadStockEntries();
            this.$store.dispatch(
              "setToast",
              {
                title: "Success",
                type: "success",
                text: "Stock Entry Created",
              },
              { root: true }
            );
          })
          .catch(() => {
            // Handle error if needed
          });
      }
    },
    resetNewStockEntry() {
      this.newStockEntry = {
        itemCode: "",
        warehouseId: "",
        transactionType: "",
        quantity: null,
        reference: "",
      };
    },
  },
  watch: {
    options: {
      handler() {
        this.loadStockEntries();
        this.loadProducts();
      },
      deep: true,
    },
  },
  beforeCreate() {
    // Register the module if it's not already registered

    if (!this.$store.hasModule("stockInventory")) {
      this.$store.registerModule("stockInventory", stockInventoryModule);
    }

    if (!this.$store.hasModule("INVENTORY_PRODUCT")) {
      this.$store.registerModule("INVENTORY_PRODUCT", productInventoryModule);
    }
  },
  beforeDestroy() {
    // Unregister the module when the component is destroyed

    if (this.$store.hasModule("stockInventory")) {
      this.$store.unregisterModule("stockInventory");
      this.$store.unregisterModule("INVENTORY_PRODUCT");
    }

    if (!this.$store.hasModule("INVENTORY_PRODUCT")) {
      this.$store.registerModule("INVENTORY_PRODUCT", productInventoryModule);
    }
  },
  created() {
    console.log("roles");
    console.log(this.$store.getters.role);
    this.loadStockEntries();
    this.loadProducts();
  },

  mounted() {
    this.loadProducts();
  },
});
