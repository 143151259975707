




































































import ActionButtons from "@/components/ActionButtons.vue";
import SaleProductList from "@/components/inventory/SaleProductList.vue";
import SupplierList from "@/components/suppliers/SupplierList.vue";
import ProductCategoryList from "@/components/inventory/ProductCategoryList.vue";
import ProductOptionList from "@/components/inventory/productOptionList.vue";
import OrderList from "@/components/inventory/OrderList.vue";
import PurchaseList from "@/components/purchase/purchaseList.vue";
import Vue from "vue";
import InStoreProductList from "@/components/inventory/InStoreProductList.vue";
import StockEntryList from "@/components/inventory/StockEntryList.vue";
import WarehouseList from "@/components/inventory/WarehouseList.vue";

export default Vue.extend<any, any, any, any>({
  components: {
    SaleProductList,
    ActionButtons,
    SupplierList,
    ProductCategoryList,
    ProductOptionList,
    OrderList,
    PurchaseList,
    InStoreProductList,
    StockEntryList,
    WarehouseList,
  },
  name: "Inventory",
  computed: {
    tab: {
      set(tab: string) {
        this.$router.replace({ query: { tab } });
      },
      get() {
        return this.$route.query.tab || "products";
      },
    },
    productTab: {
      set(productTab: string) {
        this.$router.replace({ query: { productTab } });
      },
      get() {
        return this.$route.query.productTab || "sale";
      },
    },
  },
});
