



































































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";
import { Warehouse, Page } from "@/types";
import stockInventoryModule from "@/store/modules/stock"; // Import the module

const { mapActions } = createNamespacedHelpers("stockInventory");

export default Vue.extend({
  name: "WarehouseList",
  data() {
    return {
      loading: false,
      warehouse: undefined as Warehouse | undefined,
      filters: {
        search: "",
      },
      headers: [
        { text: "Name", value: "name" },
        { text: "Location", value: "location" },
        { text: "Actions", value: "action", sortable: false },
      ],
      options: {} as { page: number; itemsPerPage: number },
      deleteDialog: false,
      addEditDialog: false,
      isEditing: false,
      warehouseForm: {
        _id: "",
        name: "",
        location: "",
      },
      rules: {
        required: (value: any) => !!value || "Required.",
      },
    };
  },
  computed: {
    // Explicitly define the computed property with its return type
    warehousePage(): Page<Warehouse> {
      return this.$store.getters["stockInventory/warehousePage"];
    },
    filteredData(): Warehouse[] {
      let data = this.warehousePage?.docs || [];

      // Apply search filter
      if (this.filters.search) {
        const search = this.filters.search.toLowerCase();
        data = data.filter(
          (item) =>
            item.name.toLowerCase().includes(search) ||
            (item.location && item.location.toLowerCase().includes(search))
        );
      }

      return data;
    },
  },
  methods: {
    ...mapActions([
      "fetchWarehouses",
      "deleteWarehouse",
      "createWarehouse",
      "updateWarehouse",
    ]),
    loadWarehouses() {
      this.loading = true;
      const params = {
        page: this.options.page || 1,
        limit: this.options.itemsPerPage || 10,
        sort: "-createdAt",
        businessId: this.$store.getters.role.business._id,
      };

      this.fetchWarehouses(params)
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    deleteConfirm(item: Warehouse) {
      this.warehouse = item;
      this.deleteDialog = true;
    },
    removeWarehouse() {
      if (this.warehouse) {
        this.deleteWarehouse({ id: this.warehouse._id })
          .then(() => {
            this.deleteDialog = false;
            this.loadWarehouses();
            this.$store.dispatch(
              "setToast",
              {
                title: "Success",
                type: "success",
                text: "Warehouse Deleted",
              },
              { root: true }
            );
          })
          .catch(() => {
            this.deleteDialog = false;
          });
      }
    },
    openAddDialog() {
      this.isEditing = false;
      this.resetWarehouseForm();
      this.addEditDialog = true;
    },
    editWarehouse(item: Warehouse) {
      this.isEditing = true;
      this.warehouseForm = {
        _id: item._id,
        name: item.name,
        location: item.location as any,
      };
      this.addEditDialog = true;
    },
    closeAddEditDialog() {
      this.addEditDialog = false;
      this.resetWarehouseForm();
    },
    submitWarehouse() {
      const valid = (this.$refs.addEditForm as any).validate();
      if (valid) {
        const payload = {
          name: this.warehouseForm.name,
          location: this.warehouseForm.location,
          businessId: this.$store.getters.role.business._id,
        };
        if (this.isEditing) {
          this.updateWarehouse({ id: this.warehouseForm._id, ...payload })
            .then(() => {
              this.closeAddEditDialog();
              this.loadWarehouses();
              this.$store.dispatch(
                "setToast",
                {
                  title: "Success",
                  type: "success",
                  text: "Warehouse Updated",
                },
                { root: true }
              );
            })
            .catch(() => {
              // Handle error if needed
            });
        } else {
          this.createWarehouse(payload)
            .then(() => {
              this.closeAddEditDialog();
              this.loadWarehouses();
              this.$store.dispatch(
                "setToast",
                {
                  title: "Success",
                  type: "success",
                  text: "Warehouse Created",
                },
                { root: true }
              );
            })
            .catch(() => {
              // Handle error if needed
            });
        }
      }
    },
    resetWarehouseForm() {
      this.warehouseForm = {
        _id: "",
        name: "",
        location: "",
      };
    },
  },
  watch: {
    options: {
      handler() {
        this.loadWarehouses();
      },
      deep: true,
    },
  },
  beforeCreate() {
    // Register the module if it's not already registered

    if (!this.$store.hasModule("stockInventory")) {
      this.$store.registerModule("stockInventory", stockInventoryModule);
    }
  },
  beforeDestroy() {
    // Unregister the module when the component is destroyed
    if (this.$store.hasModule("stockInventory")) {
      this.$store.unregisterModule("stockInventory");
    }
  },
  created() {
    this.loadWarehouses();
  },
});
